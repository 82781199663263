export const nl = {
  Login: "Inloggen",
  Logout: "Uitloggen",
  Translation: "English",
  Home: "Home",
  Link: "Link",
  Welcome: "Welkom",
  Username: "Gebruikersnaam",
  Password: "Wachtwoord",
  Send: "Verzenden",
  Documentation: "Documentatie",
  "Footer content": "Footer content",
  "Login with DigiD": "Inloggen met DigiD",
  "Login with your account": "Inloggen met account",
  "Oops, something went wrong": "Oops, er is iets fout gegaan",
  "Left scroll button": "Linker scrollknop",
  "Right scroll button": "Rechter scrollknop",
};
