export const faRedocly = {
  prefix: "fab",
  iconName: "redocly",
  icon: [
    203,
    203,
    [],
    "e001",
    "m 24.1,97.6 c -2.6,3.3 -2.6,3.5 0,6.8 l 2,2.6 h 20 c 18.6,0 20,-0.1 21.9,-2 1.1,-1.1 2,-2.9 2,-4 0,-1.1 -0.9,-2.9 -2,-4 -1.9,-1.9 -3.3,-2 -21.9,-2 h -20 z m 0,24 c -2.6,3.3 -2.6,3.5 0,6.8 l 2,2.6 h 31 c 29.6,0 31,-0.1 32.9,-2 1.1,-1.1 2,-2.9 2,-4 0,-1.1 -0.9,-2.9 -2,-4 -1.9,-1.9 -3.3,-2 -32.9,-2 h -31 z m 0,24 c -2.6,3.3 -2.6,3.5 0,6.8 l 2,2.6 h 43 c 41.6,0 43,-0.1 44.9,-2 1.1,-1.1 2,-2.9 2,-4 0,-1.1 -0.9,-2.9 -2,-4 -1.9,-1.9 -3.3,-2 -44.9,-2 h -43 z M 21.5,3.4 C 14.9,5.5 11.4,7.7 7.2,12.5 -0.3,21 0,17.3 0,102 c 0,85.1 -0.3,81.1 7.6,89.9 2.6,2.9 6.5,5.8 9.6,7.2 l 5.3,2.4 73,0.3 c 81.4,0.3 78.9,0.5 84.2,-6.4 2.8,-3.7 4.1,-11.1 2.8,-16.1 -0.4,-1.7 -5.9,-8.1 -14.1,-16.3 -7.4,-7.5 -13.4,-13.9 -13.4,-14.2 0,-0.3 3,-2.4 6.8,-4.6 16.2,-9.7 27.8,-24.3 34.2,-43.2 3.2,-9.3 3.9,-27.9 1.5,-38.6 C 190.7,31.2 165.1,7.5 133.6,3.1 122.1,1.5 26.7,1.7 21.5,3.4 Z M 138,15.6 c 22.3,5.2 40.7,22.9 47.6,46 2.5,8.1 2.5,25.7 0,33.8 -5,16.8 -16.3,31.2 -30.8,39.4 -10.2,5.7 -4.8,9.7 -55.2,-40.7 L 54.4,49 H 39.7 C 27,49 24.4,48.7 21,47 8.7,40.7 8.5,22.5 20.8,16 24.3,14.2 27,14.1 78,14.1 c 44.6,-0.1 54.6,0.2 60,1.5 z M 20,58.9 c 4.4,1.6 8,2 18,2.1 h 12.5 l 60.8,60.8 c 37.7,37.7 60.7,61.5 60.7,62.6 0,1.1 -0.7,2.8 -1.7,3.8 -1.5,1.7 -5.1,1.8 -73.5,1.8 -69.6,0 -72,-0.1 -75.8,-2 -4.5,-2.3 -7.3,-6 -8.9,-11.5 C 11.4,173.8 11,153.7 11,114.1 11,59 11.1,55.8 12.8,56.3 c 0.9,0.3 4.2,1.5 7.2,2.6 z",
  ],
};
